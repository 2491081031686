import React from "react";
import { graphql, StaticQuery } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
// import Bio from "../components/bio"
import PostCard from "../components/postCard";

import "../style/normalize.css";
import "../style/all.scss";
//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template
const personSchema = {
  "@context": "https://schema.org",
  "@type": "Person",
  name: "Trevor Lazarus",
  url: "http://trevorlazar.us",
  image: "http://trevorlazar.us/images/profile-pic.jpg",
  sameAs: [
    "https://github.com/trevorlazarus/",
    "https://scholar.google.fr/citations?user=__U4RekAAAAJ"
  ],
  jobTitle: "Knowledge Graph Engineer",
  worksFor: {
    "@type": "Organization",
    name: "Jaguar Land Rover"
  },
  alumniOf: "Loyola College, Chennai"
};
const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;
  let postCounter = 0;

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Blog"
        schema={personSchema}
        keywords={[
          `devlog`,
          `blog`,
          `gatsby`,
          `javascript`,
          `react`,
          `fpv`,
          `sparql`,
          `solid`,
          `llm`
        ]}
      />
      {/* <Bio /> */}
      {data.site.siteMetadata.description && (
        <header className="page-head">
          <h2 className="page-head-title">
            {data.site.siteMetadata.description}
          </h2>
        </header>
      )}
      <div className="post-feed">
        {posts.map(({ node }) => {
          postCounter++;
          return (
            <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
            />
          );
        })}
      </div>
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            description
            tags
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
);
